import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import './footer.scss';

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "DuckDrills-Logo-White.png" }) {
                childImageSharp {
                    fixed(height: 36, quality: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);

    return (
        <div className="Footer">
            <section className="Footer__content">
                <Img alt="Duck Drills" fixed={data.placeholderImage.childImageSharp.fixed} />
            </section>
            <section className="Footer__info">
                <p>
                    &copy; 2020 Chilitime Design. |
                    9522 Shawnee Trail, Dayton, Ohio 45458 |
                    Contact us at &nbsp;
                    <a href="mailto:michael@chilitime.design">michael@chilitime.design</a>
                </p>
            </section>
        </div>
    );
};

export default Footer;
