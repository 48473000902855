import React from 'react'

import Benefits from '../components/benefits';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Instructions from '../components/instructions';
import Compatibility from '../components/compatibility';
import Footer from '../components/footer';

const IndexPage = () => (
    <Layout>
        <SEO title="Duck Drills" />
        <Benefits />
        <Instructions />
        <Compatibility />
        <Footer />
    </Layout>
)

export default IndexPage
