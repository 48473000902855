import React from 'react';

import './benefits.scss';

const Benefits = () => (
    <div className="Benefits">
        <div className="Benefits__benefit Benefits__benefit1">
            <h3>Drills</h3>
            <p>
                Use our drill sheets to sharpen your artistic skills.
                With over a dozen to choose from (and more on the way),
                you'll always have something to work on.
            </p>
        </div>
        <div className="Benefits__benefit Benefits__benefit2">
            <h3>Templates</h3>
            <p>
                Our templates will help you start your artwork with some
                structure already in place. We have templates for making
                comic books, web comics, and more!
            </p>
        </div>
        <div className="Benefits__benefit Benefits__benefit3">
            <h3>More</h3>
            <p>
                Warm up your drawing arm by practicing the basics with
                our drill sheets. Our exercises will give you plenty of
                practice to master your digital tools and help bring
                your artwork to life.
            </p>
        </div>
    </div>
);

export default Benefits;
