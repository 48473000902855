import React from 'react';

import './compatibility.scss';

const Compatibility = () => (
    <div className="Compatibility">
        <h2>Compatibility</h2>
        <p>
            DuckDrills is compabible with the following popular
            drawing apps available for the iPad
            </p>
        <div className="Compatibility__list">
            <div className="Compatibility__app">
                <img src="/apps/icon-procreate.png" alt="Procreate" />
                <span>Procreate</span>
            </div>
            <div className="Compatibility__app">
                <img src="/apps/icon-designer.png" alt="Affinity Designer" />
                <span>Affinity Designer</span>
            </div>
            <div className="Compatibility__app">
                <img src="/apps/icon-sketchbookpro.png" alt="Sketchbook Pro" />
                <span>Sketchbook Pro</span>
            </div>
            <div className="Compatibility__app">
                <img src="/apps/icon-fresco.png" alt="Adobe Fresco" />
                <span>Adobe Fresco</span>
            </div>
            <div className="Compatibility__app">
                <img src="/apps/icon-photoshop.png" alt="Adobe Photoshop" />
                <span>Adobe Photoshop</span>
            </div>
        </div>
    </div>
);

export default Compatibility;
