import React from 'react';

import SelectIcon from './hand-pointer.svg';
import ShareIcon from './app-store-ios.svg';
import ImproveIcon from './pencil-paintbrush.svg';

import './instructions.scss';

const Instructions = () => (
    <div className="Instructions">
        <h2>Instructions</h2>
        <p>
            Using DuckDrills is simple! Follow the steps below.
        </p>
        <div className="Instructions__steps">
            <div className="Instructions__step">
                <h3>1. Select</h3>
                <div className="Instructions__info">
                    <SelectIcon className="Instructions__icon" />
                    <p>
                        Select a drill or template from your library by clicking
                        on the item you would like to do.
                    </p>
                </div>
            </div>
            <div className="Instructions__step">
                <h3>2. Share</h3>
                <div className="Instructions__info">
                    <ShareIcon className="Instructions__icon" />
                    <p>
                        On the share screen, select the drawing application you
                        would like to send the drill or template to. Popular
                        drawing applications such as Procreate and Adobe Photoshop
                        are all supported.
                    </p>
                </div>
            </div>
            <div className="Instructions__step">
                <h3>3. Improve</h3>
                <div className="Instructions__info">
                    <ImproveIcon className="Instructions__icon" />
                    <p>
                        Our exercises are designed to make you a better
                        draftsperson. Practice the basics or learn a new
                        technique.
                    </p>
                </div>
            </div>
        </div>
    </div>
);

export default Instructions;
